import axios from 'axios';
import { GET_CITY, ADD_CITY } from "../types";
import { setAlert } from './alert';

export const getCity = () => async (dispatch) => {
    const url = `${process.env.REACT_APP_BE_URL}/cities`;
    axios
        .get(url)
        .then((res) => {
            dispatch({ type: GET_CITY, payload: res?.data?.data });
        })
        .catch((error) => {
            dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
        });
};