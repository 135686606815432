import axios from 'axios';
import { GET_CINEMAS, GET_CINEMA } from '../types';
import { setAlert } from './alert';

export const uploadCinemaImage = (image) => async (dispatch) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    };

    const data = new FormData();
    data.append('image', image); // Update the key to 'image' as per the Swagger documentation

    // const url = `http://localhost:8000/api/v1/images`; // Update the URL based on your Swagger documentation
    // const url = `https://backend.cinebooking.lk/api/v1/images`; // Update the URL based on your Swagger documentation
    const url = `${process.env.REACT_APP_BE_URL}/images`;

    const response = await axios.post(url, data, { headers });

    if (response.data) {
      const id = response.data.data.id;
      dispatch(setAlert('Image Uploaded', 'success', 5000));
      return id;
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
  }
};

export const getCinemas = (isAdmin) => async (dispatch) => {
  if (isAdmin) {
    try {
      const token = localStorage.getItem('jwtToken');
      const url = `${process.env.REACT_APP_BE_URL}/cinemas?fields=id,name,description,images,location,hasAC,hasFan,slug,updatedAt,createdAt,seats,seatsAvailableODC,isDraft,seatsAvailableBALCONY&draft=true`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const cinemas = await response.json();
      if (response.ok) {
        dispatch({ type: GET_CINEMAS, payload: cinemas.data });
      }
    } catch (error) {
      dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    }
  } else {
    try {
      const url = `${process.env.REACT_APP_BE_URL}/cinemas?fields=id,name,description,images,location,hasAC,hasFan,slug,updatedAt,createdAt,seats,seatsAvailableODC,seatsAvailableBALCONY`;
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      const cinemas = await response.json();
      if (response.ok) {
        dispatch({ type: GET_CINEMAS, payload: cinemas.data });
      }
    } catch (error) {
      dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    }
  }
};

export const getCinema = (id) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BE_URL}/cinemas/getcinema/${id}`;

  axios
    .get(url)
    .then((res) => {
      dispatch({ type: GET_CINEMA, payload: res.data.data });
    })
    .catch((error) => {
      dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    });
};

export const createCinemas = (image, newCinema) => async (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  await dispatch(uploadCinemaImage(image)).then((imageId) => {
    const url = `${process.env.REACT_APP_BE_URL}/cinemas`;
    axios
      .post(
        url,
        {
          name: newCinema.name,
          description: newCinema.description,
          location: newCinema.location,
          hasAC: newCinema.hasAC,
          hasFan: newCinema.hasFan,
          seatsAvailableBALCONY: parseInt(newCinema.seatsAvailableBALCONY),
          seatsAvailableODC: parseInt(newCinema.seatsAvailableODC),
          images: [imageId],
          slug: newCinema.slug,
          isDraft: newCinema.isDraft,
          seats: newCinema.seats
        },
        { headers }
      )
      .then((response) => {
        dispatch(setAlert('theatre have been saved!', 'success', 5000));
        dispatch(getCinemas(true));
        return { status: 'success', message: 'Cinema Created' };
      })
      .catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
      });
  });
};

export const updateCinemas = (image, updatedCinema, id) => async (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  if (image) {
    await dispatch(uploadCinemaImage(image)).then((imageId) => {
      const url = `${process.env.REACT_APP_BE_URL}/cinemas/updatecinema/${id}`;
      axios
        .put(
          url,
          {
            name: updatedCinema.name,
            description: updatedCinema.description,
            location: updatedCinema.location,
            hasAC: updatedCinema.hasAC,
            hasFan: updatedCinema.hasFan,
            seatsAvailableBALCONY: parseInt(
              updatedCinema.seatsAvailableBALCONY
            ),
            seatsAvailableODC: parseInt(updatedCinema.seatsAvailableODC),
            images: [imageId],
            slug: updatedCinema.slug,
            isDraft: updatedCinema.isDraft,
            seats: updatedCinema.seats
          },
          { headers }
        )
        .then((response) => {
          dispatch(setAlert('Cinema Updated', 'success', 5000));
          dispatch(getCinemas(true));
          return { status: 'success', message: 'Cinema Updated' };
        })
        .catch((error) => {
          dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
          return {
            status: 'error',
            message: ' Cinema have not been updated, try again.'
          };
        });
    });
  } else {
    const url = `${process.env.REACT_APP_BE_URL}/cinemas/updatecinema/${id}`;
    axios
      .put(
        url,
        {
          name: updatedCinema.name,
          description: updatedCinema.description,
          location: updatedCinema.location,
          hasAC: updatedCinema.hasAC,
          hasFan: updatedCinema.hasFan,
          seatsAvailableBALCONY: parseInt(updatedCinema.seatsAvailableBALCONY),
          seatsAvailableODC: parseInt(updatedCinema.seatsAvailableODC),
          slug: updatedCinema.slug,
          isDraft: updatedCinema.isDraft,
          seats: updatedCinema.seats
        },
        { headers }
      )
      .then((response) => {
        dispatch(setAlert('Cinema Updated', 'success', 5000));
        dispatch(getCinemas(true));
        return { status: 'success', message: 'Cinema Updated' };
      })
      .catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
        return {
          status: 'error',
          message: ' Cinema have not been updated, try again.'
        };
      });
  }
};

export const removeCinemas = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/cinemas/deletecinema/${id}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    axios
      .delete(url, { headers })
      .then((res) => {
        dispatch(setAlert('Cinema Deleted', 'success', 5000));
        dispatch(getCinemas(true));
        return { status: 'success', message: 'Cinema Removed' };
      })
      .catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
      });
  } catch (error) {
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
    return {
      status: 'error',
      message: ' Cinema have not been deleted, try again.'
    };
  }
};

export const getCinemasUserModeling = (username) => async (dispatch) => {
  try {
    const url = '/cinemas/usermodeling/' + username;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const cinemas = await response.json();
    if (response.ok) {
      dispatch({ type: GET_CINEMAS, payload: cinemas });
    }
  } catch (error) {
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
  }
};
